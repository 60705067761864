import React from "react"
import { Typography } from "antd"

const { Title } = Typography

const ColumnTitle = ({ align, text, subtext }) => {
  return (
    <>
      <Title level={2} style={{ textAlign: `${align}` }}>
        {text}
      </Title>
      {subtext ? (
        <div className="subTitle">
          {subtext}
        </div>
      ) : null}
    </>
  )
}

export default ColumnTitle
