import "./form.css"

import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Form as Frm,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Typography,
} from "antd"

import ColumnTitle from "../layout/columnTitle"
import Cookies from "js-cookie"
import LayoutSpacer from "../layout/layoutSpacer"
import React from "react"
import Seal from "../seal"
import TrialBadge from "../../images/free-trial-badge.png"
import { v4 as uuid } from "uuid"

const { Title } = Typography
const InputGroup = Input.Group

export const FORM_STORAGE_KEY = "signup_data"

class Form extends React.Component {
  constructor(props) {
    super(props)

    this.storageKey = FORM_STORAGE_KEY

    this.state = {
      waiting: false,
      values: {
        app_type: "cku_red",
        no_zip_code: false,
        termsandconditions: true,
      },
    }

    this.hasErrorsOrIsValidating = this.hasErrorsOrIsValidating.bind(this)
    this.submit = this.submit.bind(this)
    this.updateState = this.updateState.bind(this)
    this.redirectToCheckout = this.redirectToCheckout.bind(this)
    this.updateCheckbox = this.updateCheckbox.bind(this)
    this.restoreState = this.restoreState.bind(this)
    this.storeState = this.storeState.bind(this)
    this.checkPasswordConfirm = this.checkPasswordConfirm.bind(this)
    this.checkEmail = this.checkEmail.bind(this)
    this.checkZipcode = this.checkZipcode.bind(this)
    this.date_of_birth_month_validator = this.date_of_birth_month_validator.bind(
      this
    )
    this.date_of_birth_day_validator = this.date_of_birth_day_validator.bind(
      this
    )

    this.settings = {
      title: props.overrides?.title || 'Set up your free trial',
      showSeal: props.overrides?.showSeal || 'yes',
    }
  }

  componentDidMount() {
    const affiliate = Cookies.get('affiliate');
    this.clientReferenceId = `${uuid()}${affiliate? `;aid:${affiliate}`: ''}`

    this.stripe = window.Stripe(process.env.STRIPE_CHECKOUT)
    this.successUrl = `${window.location.origin}/success/`
    this.cancelUrl = window.location.origin + window.location.pathname + '?cancel'
    this.restoreState()
  }

  restoreState() {
    const state =
      JSON.parse(window.sessionStorage.getItem(this.storageKey)) || {}
    state.modified = state.waiting = false
    this.updateState(state)
  }

  storeState() {
    window.sessionStorage.setItem(this.storageKey, JSON.stringify(this.state))
  }

  redirectToCheckout() {
    const frm = this
    const data = { ...frm.state.values }
    if (data.no_zip_code) {
      data.zip_code = "outside the U.S."
    }
    delete data.no_zip_code
    data.date_of_birth = `${String(data.date_of_birth_month).padStart(
      2,
      "0"
    )}/${String(data.date_of_birth_day).padStart(2, "0")}/${
      data.date_of_birth_year
    }`

    const errorHandler = function(e) {
      console.error("Signup failed", e)
    }

    const successHandler = function() {
      frm.stripe
        .redirectToCheckout({
        items: [{ plan: process.env.STRIPE_PLAN, quantity: 1 }],
        customerEmail: data.email,
        successUrl: frm.successUrl,
        cancelUrl: frm.cancelUrl,
        billingAddressCollection: "required",
        clientReferenceId: frm.clientReferenceId,
      })
        .then(errorHandler)
    }

    if (frm.state.modified) {
      fetch("/api/subscribers", {
        method: "POST",
        cache: "no-cache",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then(function(resp) {
          if (resp.ok) {
            successHandler()
          } else {
            errorHandler(resp)
          }
        })
        .catch(errorHandler)
    } else {
      successHandler()
    }
  }

  updateCheckbox(event) {
    const vals = { ...this.state.values }
    vals[event.target.name] = event.target.checked
    this.updateState({ values: vals })
  }

  submit(event) {
    event.preventDefault()
    this.updateState(
      {
        waiting: true,
        values: { ...this.state.values, ...this.getValues() },
        modified: this.state.modified || this.props.form.isFieldsTouched(),
      },
      this.redirectToCheckout
    )
  }

  updateState(newState, after_update) {
    return this.setState(newState, function() {
      this.storeState()
      this.props.form.validateFields()
      if (after_update) {
        after_update()
      }
    })
  }

  checkPasswordConfirm(rule, value, callback) {
    if (value !== this.props.form.getFieldValue("password")) {
      callback("Confirmation doesn't match the password")
    } else {
      callback()
    }
  }

  checkEmail(rule, value, callback) {
    fetch(`/api/subscribers/check_email?email=${encodeURIComponent(value)}`)
      .then(resp => {
        return resp.json()
      })
      .then(resp => {
        if (resp.email !== this.props.form.getFieldValue("email")) {
          callback("Value changed")
        } else {
          if (this.state.email_user_type !== resp.user_type) {
            this.updateState({ email_user_type: resp.user_type })
          }
          callback(resp.user_type || undefined) //callback can't get null
        }
      })
      .catch(e => {
        console.error(e)
        callback("Something went wrong")
      })
  }

  checkZipcode(rule, value, callback) {
    if(!value){ return callback(); }
    fetch(`/api/subscribers/check_zipcode?zipcode=${value}`)
      .then(resp => {
        return resp.json()
      })
      .then(resp => {
        if (resp.zipcode != this.props.form.getFieldValue("zip_code")) { // eslint-disable-line eqeqeq
          callback("Value changed")
        } else {
          if (this.state.district_url !== resp.district_url) {
            this.updateState({ district_url: resp.district_url })
          }
          callback(resp.district_url || undefined) //callback can't get null
        }
      })
      .catch(e => {
        console.error(e)
        callback("Something went wrong")
      })
  }

  date_of_birth_month_validator(rule, month, callback) {
    const year = this.props.form.getFieldValue("date_of_birth_year")
    const date = new Date(`${year}-${String(month).padStart(2, "0")}-01`)
    callback((year && date > new Date() && "Invalid date") || undefined) // eslint-disable-line no-mixed-operators
  }

  date_of_birth_day_validator(rule, day, callback) {
    const year = this.props.form.getFieldValue("date_of_birth_year") || 2012
    const month = this.props.form.getFieldValue("date_of_birth_month")
    const date = new Date(
      `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
        2,
        "0"
      )}`
    )
    const error = Object.is(date.valueOf(), NaN) || date > new Date()
    callback((month && error && "Invalid date") || undefined) // eslint-disable-line no-mixed-operators
  }

  hasErrorsOrIsValidating(fieldsError) {
    const frm = this
    return Object.keys(fieldsError).some(function(field) {
      return fieldsError[field] || frm.props.form.isFieldValidating(field)
    })
  }

  getValues() {
    const values = this.props.form.getFieldsValue()
    Object.keys(values).forEach(function(k) {
      values[k] === undefined && delete values[k]
    })
    return values
  }

  render() {
    const frm = this
    const {
      getFieldsError,
      getFieldDecorator,
      isFieldTouched,
      isFieldValidating,
      getFieldError,
      validateFields,
    } = frm.props.form

    const getValidateStatus = function(id) {
      if (isFieldValidating(id)) {
        return "validating"
      }
      const no_zip_code = id === "zip_code" && frm.state.values.no_zip_code
      return isFieldTouched(id) ||
        frm.state.values.hasOwnProperty(id) ||
        no_zip_code
        ? getFieldError(id)
          ? "error"
          : "success"
        : ""
    }

    return (
      <LayoutSpacer>
        <Frm layout="horizontal" onSubmit={frm.submit}>
          <article className="form-container">
            <div
              className="trial-badge-container"
            >
              <img
                width="100%"
                alt="14 Day Free Trial, then only $7.95"
                src={TrialBadge}
              />
            </div>
            <ColumnTitle align="left" text={this.settings.title} />
            <InputGroup>
              <Row>
                <Col span={8}>
                  <Frm.Item
                    help=""
                    hasFeedback
                    validateStatus={getValidateStatus("zip_code")}
                  >
                    {getFieldDecorator("zip_code", {
                      initialValue: frm.state.values.zip_code,
                      validateFirst: true,
                      rules: [
                        {
                          required: !frm.state.values.no_zip_code,
                          transform: v => {
                            return v > 0 ? String(v) : ""
                          },
                        },
                        {
                          validator: frm.checkZipcode,
                          transform: v => {
                            return !frm.state.values.no_zip_code && v > 0
                              ? String(v).padStart(5, "0")
                              : null
                          },
                        },
                      ],
                    })(
                      <InputNumber
                        className="zipcode-input"
                        type="number"
                        disabled={frm.state.values.no_zip_code}
                        name="zip_code"
                        placeholder="Zip code"
                        min={0}
                        max={99999}
                        formatter={function(v) {
                          return v > 0 ? String(v).padStart(5, "0") : ""
                        }}
                        parser={function(s) {
                          return Number(s.replace(/\D/, "").slice(-5))
                        }}
                      />
                    )}
                  </Frm.Item>
                </Col>
                <Col xs={24} sm={16} md={24}>
                  <Frm.Item>
                    {getFieldDecorator("no_zip_code", {
                      valuePropName: "checked",
                      initialValue: frm.state.values.no_zip_code,
                    })(
                      <Checkbox
                        name="no_zip_code"
                        onChange={frm.updateCheckbox}
                      >
                        We do not live in the U.S.
                      </Checkbox>
                    )}
                  </Frm.Item>
                </Col>
              </Row>
            </InputGroup>
            {(!frm.state.values.no_zip_code && frm.state.district_url && (
              <Alert
                message={
                  <Spin spinning={isFieldValidating("zip_code")}>
                    Your school already purchased Clever Kids University for
                    your child.
                    <br />
                    <a href={frm.state.district_url}>
                      Click here to register for a FREE account.
                    </a>
                  </Spin>
                }
              />
            )) || (
              <>
                <Frm.Item
                  help=""
                  hasFeedback
                  validateStatus={getValidateStatus("email")}
                >
                  {getFieldDecorator("email", {
                    initialValue: frm.state.values.email,
                    validateFirst: true,
                    rules: [
                      { type: "email", required: true },
                      { validator: frm.checkEmail },
                    ],
                  })(
                    <Input
                      name="email"
                      type="text"
                      placeholder="Email address"
                      className="auth-input"
                    />
                  )}
                </Frm.Item>
                {(frm.state.email_user_type === "consumer" && (
                  <Alert
                    message={
                      <Spin spinning={isFieldValidating("email")}>
                        It appears that you had a Clever Kids U membership in
                        the past.
                        <br />
                        {"Please contact "}
                        <a href="mailto: support@cleverkidsu.com">
                          support@cleverkidsu.com
                        </a>
                        {" to update or renew your subscription."}
                      </Spin>
                    }
                  />
                )) ||
                  (frm.state.email_user_type === "parent" && (
                    <Alert
                      message={
                        <Spin spinning={isFieldValidating("email")}>
                          It appears you have a parent Footsteps2Brilliance
                          account with your school district.
                          <br />
                          Please enter an alternate email address for your
                          family membership to Clever Kids University.
                        </Spin>
                      }
                    />
                  )) ||
                  (frm.state.email_user_type && (
                    <Alert
                      message={
                        <Spin spinning={isFieldValidating("email")}>
                          {`It appears you have a ${frm.state.email_user_type} Footsteps2Brilliance account.`}
                          <br />
                          Please enter a personal email so that we can create a
                          family account for you.
                        </Spin>
                      }
                    />
                  )) || (
                    <>
                      <Frm.Item
                        help="Minimum 8 characters"
                        hasFeedback
                        validateStatus={getValidateStatus("password")}
                      >
                        {getFieldDecorator("password", {
                          initialValue: frm.state.values.password,
                          rules: [{ min: 8, required: true }],
                        })(
                          <Input.Password
                            name="password"
                            placeholder="Password"
                            className="auth-input"
                          />
                        )}
                      </Frm.Item>
                      <Frm.Item
                        help=""
                        hasFeedback
                        validateStatus={getValidateStatus("password_confirm")}
                      >
                        {getFieldDecorator("password_confirm", {
                          initialValue: frm.state.values.password_confirm,
                          rules: [
                            { validator: frm.checkPasswordConfirm },
                            { required: true },
                          ],
                        })(
                          <Input.Password
                            name="password_confirm"
                            placeholder="Confirm password"
                            className="auth-input"
                          />
                        )}
                      </Frm.Item>
                      <Card className="child-data">
                        <Row gutter={24} type="flex" align="middle">
                          {this.settings.showSeal === "yes" && (
                            <Col flex="130px" className="seal-container">
                              <Seal />
                            </Col>
                          )}
                          <Col flex="auto">
                            <Title level={4}>Child's name</Title>
                            <Row gutter={8}>
                              <Col xs={24} sm={12}>
                                <Frm.Item
                                  help=""
                                  hasFeedback
                                  validateStatus={getValidateStatus(
                                    "first_name"
                                  )}
                                >
                                  {getFieldDecorator("first_name", {
                                    initialValue: frm.state.values.first_name,
                                    rules: [{ required: true }],
                                  })(
                                    <Input
                                      name="first_name"
                                      type="text"
                                      placeholder="Child's First Name"
                                    />
                                  )}
                                </Frm.Item>
                              </Col>
                              <Col xs={24} sm={12}>
                                <Frm.Item
                                  help=""
                                  hasFeedback
                                  validateStatus={getValidateStatus(
                                    "last_name"
                                  )}
                                >
                                  {getFieldDecorator("last_name", {
                                    initialValue: frm.state.values.last_name,
                                    rules: [{ required: true }],
                                  })(
                                    <Input
                                      name="last_name"
                                      type="text"
                                      placeholder="Child's Last Initial"
                                    />
                                  )}
                                </Frm.Item>
                              </Col>
                            </Row>
                            <Title level={4}>Child's birthday</Title>
                            <Row gutter={8}>
                              <Col span={8}>
                                <Frm.Item
                                  help=""
                                  hasFeedback
                                  validateStatus={getValidateStatus(
                                    "date_of_birth_month"
                                  )}
                                >
                                  {getFieldDecorator("date_of_birth_month", {
                                    initialValue:
                                      frm.state.values.date_of_birth_month,
                                    rules: [
                                      {
                                        required: true,
                                        validator:
                                          frm.date_of_birth_month_validator,
                                      },
                                      {
                                        validator: (r, v, callback) => {
                                          validateFields(["date_of_birth_day"])
                                          callback()
                                        },
                                      },
                                    ],
                                  })(
                                    <Select placeholder="Month">
                                      {[
                                        "January",
                                        "February",
                                        "March",
                                        "April",
                                        "May",
                                        "June",
                                        "July",
                                        "August",
                                        "September",
                                        "October",
                                        "November",
                                        "December",
                                      ].map((month, index) => (
                                        <Select.Option
                                          value={index + 1}
                                          key={index}
                                        >
                                          {month}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  )}
                                </Frm.Item>
                              </Col>
                              <Col span={8}>
                                <Frm.Item
                                  help=""
                                  hasFeedback
                                  validateStatus={getValidateStatus(
                                    "date_of_birth_day"
                                  )}
                                >
                                  {getFieldDecorator("date_of_birth_day", {
                                    initialValue:
                                      frm.state.values.date_of_birth_day,
                                    rules: [
                                      {
                                        required: true,
                                        validator:
                                          frm.date_of_birth_day_validator,
                                      },
                                    ],
                                  })(
                                    <Select placeholder="Day">
                                      {Array(31)
                                        .fill(0)
                                        .map((v, i) => i + 1)
                                        .map(day => (
                                          <Select.Option value={day} key={day}>
                                            {String(day).padStart(2, "0")}
                                          </Select.Option>
                                        ))}
                                    </Select>
                                  )}
                                </Frm.Item>
                              </Col>
                              <Col span={8}>
                                <Frm.Item
                                  help=""
                                  hasFeedback
                                  validateStatus={getValidateStatus(
                                    "date_of_birth_year"
                                  )}
                                >
                                  {getFieldDecorator("date_of_birth_year", {
                                    initialValue:
                                      frm.state.values.date_of_birth_year,
                                    rules: [
                                      {
                                        required: true,
                                      },
                                      {
                                        validator: (r, v, callback) => {
                                          validateFields([
                                            "date_of_birth_day",
                                            "date_of_birth_month",
                                          ])
                                          callback()
                                        },
                                      },
                                    ],
                                  })(
                                    <Select placeholder="Year">
                                      {Array(11)
                                        .fill(0)
                                        .map(
                                          (v, i) => new Date().getFullYear() - i
                                        )
                                        .reverse()
                                        .map(year => (
                                          <Select.Option
                                            value={year}
                                            key={year}
                                          >
                                            {year}
                                          </Select.Option>
                                        ))}
                                    </Select>
                                  )}
                                </Frm.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </>
                  )}
              </>
            )}
            <div id="submit-button-holder">
              {"By clicking below, you agree to the "}
              <a
                href="https://www.footsteps2brilliance.com/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
              {" and the "}
              <a
                href="https://www.footsteps2brilliance.com/privacypolicy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </div>
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              loading={frm.state.waiting}
              disabled={frm.hasErrorsOrIsValidating(getFieldsError())}
            >
              Try for FREE &rarr;
            </Button>
          </article>
        </Frm>
      </LayoutSpacer>
    )
  }
}

export default Frm.create({ name: "signup" })(Form)
