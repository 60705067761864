import React from "react"
import sealImage from "../images/child-safe-seal.png"

const Seal = () => {
  return (
    <img width="100px" src={sealImage} alt="child safety seal" />
  )
}

export default Seal
